<form id="enquiry-golf" (submit)="bookAirportVipPrivileges()" [formGroup]="bookAirportVipPrivilegesForm" *ngIf="showGuestCountvalidation">
    <mat-dialog-content class="mat-typography modal-bg">
        <h2 mat-dialog-title class="block">
            <span class="dialog-modal-title left">Send booking request</span>
            <img src="../../assets/axis-a-logo-white.png" alt="logo" class="dialog-modal-logo img-fluid left">
        </h2>
        <div class="sub-title-grey">Once you submit your enquiry, we will check availability and a confirmation email will be sent at least 15 hours before travel time.</div>
        <div class="flex booking-count-container" *ngIf="data?.bookings_count">
            <div class="each-count mar-right-10">
                <div class="f600">Eligibility:</div>
                <div>{{data?.bookings_count.eligibility_count + ' free sessions'}}</div>
            </div>
            <div class="each-count mar-right-10">
                <div class="f600">Usage:</div>
                <div>{{data?.bookings_count.availed + ' sessions utilized'}}</div>
            </div>
            <div class="each-count mar-right-10">
                <div class="f600">Remaining:</div>
                <div>{{data?.bookings_count.pending + ' free sessions'}}</div>
            </div>
        </div>
        <div class="send-inquiry-body">
            <div class="send-inquiry-form">
                <div class="col-xs-12 form-single-container">
                    <label for="golf-enquiry-bin" class="inp-label">Enter first 8-digits of your Card</label>
                    <input class="form-control golf-bin-capture" type="text" id="airport_vip_privileges_bin" maxlength="8" autocomplete="off" formControlName="airport_vip_privileges_bin" (input)="cardbinValidate($event.target.value)" disabled>
                    <span class="bin-error" *ngIf="invalidBin">Invalid Bin</span>
                    <span class="bin-error" *ngIf="showError">{{ this.resErrorMessage }}</span>
                </div>
                <div>
                    <div *ngIf="showAirportVipPrivilegeFields">
                        <div class="col-xs-12 user-form" id="show-form">
                            <div class="row">
                                <div class="cols col-sm-6 form-single-container">
                                    <div class="enq-num inp-label">First Name</div>
                                    <input id="first_name" class="form-control" type="text" formControlName="first_name" required>
                                    <div *ngIf="first_name.invalid && (first_name.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="first_name.errors.required">Please Enter your First Name.</div>
                                    </div>
                                </div>
                                <div class="cols col-sm-6 form-single-container">
                                    <div class="enq-num inp-label">Last Name</div>
                                    <input id="last_name" class="form-control" type="text" formControlName="last_name" required>
                                    <div *ngIf="last_name.invalid && (last_name.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="last_name.errors.required">Please Enter your Last Name.</div>
                                    </div>
                                </div>
                                <div class="cols col-sm-6 form-single-container">
                                    <div class="enq-num inp-label">Mobile Number</div>
                                    <input id="mobile_number" class="form-control bg-light" type="text" maxlength="10" minlength="10" formControlName="mobile_number" disabled [readonly]="true" required>
                                    <div *ngIf="mobile_number.invalid && (mobile_number.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="mobile_number.errors.required">Mobile Number is required.</div>
                                        <div *ngIf="mobile_number.errors.pattern">Mobile Number is invalid.</div>
                                    </div>
                                </div>
                                <div class="cols col-sm-6 form-single-container">
                                    <div class="enq-num inp-label">Email</div>
                                    <input id="email" class="form-control bg-light" type="text" formControlName="email" disabled [readonly]="true" required>
                                    <div *ngIf="email.invalid && (email.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="email.errors.required">Email is required.</div>
                                        <div *ngIf="email.errors.pattern">Invalid email.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-sm-12 form-single-container">
                                    <div class="inp-label">Cities</div>
                                    <div class="d-flex flex-column">
                                        <select class="form-control form-select" id="city-selection" (change)="setTravelSectorFilter()" formControlName="city">
                                            <option disabled selected value> -- select an option -- </option>
                                            <option *ngFor="let city of getCityList()" [value]="city">{{city}}</option>
                                        </select>
                                        <div *ngIf="!bookAirportVipPrivilegesForm.value?.city && city.touched" class="alert alert-danger text-nowrap">
                                            Please select city above.
                                        </div>
                                    </div>
                                </div>



                                <div class="col-sm-6 form-single-container">
                                    <div class="inp-label">Travel type</div>
                                    <div>
                                        <select class="form-control form-select" id="travel-sector" (change)="setUserCategoryValidators()" formControlName="travel_sector">
                                            <!-- <option value="Domestic">Domestic</option>
                    <option value="International">International</option> -->
                                            <option *ngFor="let type of travelType" [value]="type">{{type}}</option>
                                        </select>
                                        <!-- <i class="fa fa-angle-down select-down"></i> -->
                                    </div>
                                    <div class="d-flex">
                                        <div *ngIf="!bookAirportVipPrivilegesForm.value?.city && travel_sector.touched" class="alert alert-danger text-nowrap">
                                            Please select city above.
                                        </div>
                                        <div *ngIf="travel_sector.invalid && (submitButtonSubmitted)" class="alert alert-danger text-nowrap">
                                            <div *ngIf="travel_sector.errors.required">Travel type is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 form-single-container">
                                    <div class="inp-label">Travel sector </div>
                                    <div>
                                        <select class="form-control form-select" id="assistance-type" formControlName="assistance_type">
                                            <option value="">Select Travel Sector</option>
                                            <option value="Arrival" *ngIf="isServiceAvailable('arrival')">Arrival</option>
                                            <option value="Departure" *ngIf="isServiceAvailable('departure')">Departure</option>
                                        </select>
                                        <!-- <i class="fa fa-angle-down select-down"></i> -->
                                    </div>
                                    <div *ngIf="assistance_type.invalid && (assistance_type.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="assistance_type.errors.required">Assistance type is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-12 form-single-container choose-airport">
                                    <div class="inp-label">Choose the airport</div>
                                    <div>
                                        <select class="form-control form-select" id="airport" formControlName="airport" [(ngModel)]="airportName">
                                            <option value=""></option>
                                            <option *ngFor="let airport of filterAirport()" [value]="airport?.airport">
                                                {{airport?.airport}}
                                            </option>
                                        </select>
                                        <!-- <i class="fa fa-angle-down select-down"></i> -->
                                    </div>
                                    <div *ngIf="airport.invalid && (airport.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="airport.errors.required">Airport is required.</div>
                                    </div>
                                    <p class="choose_airpot_tag"> <strong>*</strong> <span>"The services offered may
                                            differ on the basis
                                            of the airport chosen. For detailed services across all
                                            airports, <a href="../../assets/Airport-concierge-services_list.pdf"
                                                target="_blank">click
                                                here</a></span></p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6 form-single-container">
                                    <div class="inp-label">Travel date</div>
                                    <mat-form-field>
                                        <input matInput (click)="booking_date.open();" [matDatepickerFilter]="dateFilter" [matDatepicker]="booking_date" id="booking_date" class="form-control" formControlName="booking_date" autocomplete="off" required>
                                        <mat-datepicker-toggle matSuffix [for]="booking_date"></mat-datepicker-toggle>
                                        <mat-datepicker #booking_date></mat-datepicker>
                                    </mat-form-field>
                                    <div *ngIf="bookAirportVipPrivilegesForm.controls.booking_date.invalid && (bookAirportVipPrivilegesForm.controls.booking_date.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="bookAirportVipPrivilegesForm.controls.booking_date.errors.required">
                                            Travel Date is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="inp-label">Hours</div>
                                    <div>
                                        <select class="form-control form-select" id="booking-time" formControlName="booking_time_hour">
                                            <option *ngFor="let time of filterHourTimeList()" [value]="time">{{time}}
                                            </option>
                                        </select>
                                        <!-- <i class="fa fa-angle-down select-down"></i> -->
                                    </div>
                                    <div *ngIf="booking_time_hour?.invalid && (booking_time_hour?.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="booking_time_hour?.errors?.required">Booking time is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="inp-label">Minutes</div>
                                    <div>
                                        <select class="form-control form-select" id="booking-time" formControlName="booking_time_minute">
                                            <option *ngFor="let time of filterMinutesTimeList()" [value]="time">{{time}}
                                            </option>
                                        </select>
                                        <!-- <i class="fa fa-angle-down select-down"></i> -->
                                    </div>
                                    <div *ngIf="booking_time_minute?.invalid && (booking_time_minute?.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="booking_time_minute?.errors?.required">Booking time is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="travel_sector.value == 'International'">
                                <div class="col-sm-6 form-single-container">
                                    <div class="inp-label">Passport</div>
                                    <input id="passport" class="form-control" type="text" formControlName="passport">
                                    <div *ngIf="passport.invalid && (passport.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="passport.errors.required">Passport is required.</div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row ">
                                <div class="radio-golf-type singlr-radio-box col-sm-6 noPadding">
                                    <div class="col-sm-12 inp-label">Accompanying guests, if any</div>
                                    <div class="flex w100">
                                        <div class="col-sm-6">
                                            <div class="radio radio-primary radio-inline">
                                                <input id="yes" type="radio" value="yes" (change)="set_guest_count(1)" formControlName="bookingForGuest">
                                                <label for="yes">Yes</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="radio radio-primary radio-inline">
                                                <input id="no" type="radio" value="no" (change)="set_guest_count(0)" formControlName="bookingForGuest">
                                                <label for="no">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="bookingForGuest.invalid && (bookingForGuest.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="bookingForGuest.errors.required" class="cols col-sm-12">Please select one option, required.
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="bookingForGuest.value == 'yes' && totalCount > 6" class="choose_airpot_tag col-sm-12 " style="color:red;"> <strong>*</strong>
                                    <span>Total eligibility guest count is exceeded</span>
                                </p>
                                <div class="col-sm-6 input-top-margin" *ngIf="bookingForGuest.value == 'yes' && totalCount < 7">
                                    <div class="inp-label">Number of the guests:</div>
                                    <div>
                                        <select class="form-control form-select" id="guest_count" (change)="change_guest_count()" formControlName="guest_count">
                                            <option *ngFor="let guest of guestList">{{guest}}</option>
                                        </select>
                                        <!-- <i class="fa fa-angle-down select-down"></i> -->
                                    </div>
                                </div>
                            </div>
                            <div class="cols col-sm-12 margin-b-10" *ngIf="guest_count_number >= 1 && bookingForGuest.value == 'yes' && totalCount < 7 ">
                                <div class="enq-num inp-label">Name of guest 1</div>
                                <input id="guest_name_1" class="form-control" type="text" formControlName="guest_name_1" ruired>
                                <div *ngIf="guest_name_1 && guest_name_1.invalid && (guest_name_1.touched || submitButtonSubmitted)" class="alert alert-danger">
                                    <div *ngIf="guest_name_1?.errors.required">Name of guests is required.</div>
                                </div>
                            </div>
                            <div class="cols col-sm-12 margin-b-10" *ngIf="guest_count_number >= 1 && bookingForGuest.value == 'yes' && travel_sector.value =='International'&& totalCount < 7">
                                <div class="enq-num inp-label">Passport of guest 1</div>
                                <input id="guest_passport_1" class="form-control" type="text" formControlName="guest_passport_1">
                                <div *ngIf="guest_passport_1 && guest_passport_1.invalid && (guest_passport_1.touched || submitButtonSubmitted)" class="alert alert-danger">
                                    <div *ngIf="guest_passport_1?.errors.required">Passport of guest is required.</div>
                                </div>
                            </div>
                            <div class="cols col-sm-12 margin-b-10 form-single-block" *ngIf="guest_count_number >= 2 && bookingForGuest.value == 'yes'">
                                <div class="enq-num inp-label">Name of guest 2</div>
                                <input id="guest_name_2" class="form-control" type="text" formControlName="guest_name_2">
                                <div *ngIf="guest_name_2 && guest_name_2.invalid && (guest_name_2.touched || submitButtonSubmitted)" class="alert alert-danger">
                                    <div *ngIf="guest_name_2?.errors.required">Name of guest is required.</div>
                                </div>
                            </div>
                            <div class="cols col-sm-12 margin-b-10 form-single-block" *ngIf="guest_count_number >= 2 && bookingForGuest.value == 'yes' && travel_sector.value =='International'">
                                <div class="enq-num inp-label">Passport of guest 2</div>
                                <input id="guest_passport_2" class="form-control" type="text" formControlName="guest_passport_2">
                                <div *ngIf="guest_passport_2 && guest_passport_2.invalid && (guest_passport_2.touched || submitButtonSubmitted)" class="alert alert-danger">
                                    <div *ngIf="guest_passport_2?.errors.required">Passport of guest is required.</div>
                                </div>
                            </div>
                            <div class="cols col-sm-12 margin-b-10 form-single-block" *ngIf="guest_count_number >= 3 && bookingForGuest.value == 'yes'">
                                <div class="enq-num inp-label">Name of guest 3</div>
                                <input id="guest_name_3" class="form-control" type="text" formControlName="guest_name_3">
                                <div *ngIf="guest_name_3 && guest_name_3.invalid && (guest_name_3.touched || submitButtonSubmitted)" class="alert alert-danger">
                                    <div *ngIf="guest_name_3?.errors.required">Name of guest is required.</div>
                                </div>
                            </div>
                            <div class="cols col-sm-12 margin-b-10 form-single-block" *ngIf="guest_count_number >= 3 && bookingForGuest.value == 'yes' && travel_sector.value =='International'">
                                <div class="enq-num inp-label">Passport of guest 3</div>
                                <input id="guest_passport_3" class="form-control" type="text" formControlName="guest_passport_3">
                                <div *ngIf="guest_passport_3 && guest_passport_3.invalid && (guest_passport_3.touched || submitButtonSubmitted)" class="alert alert-danger">
                                    <div *ngIf="guest_passport_3?.errors.required">Passport of guest is required.</div>
                                </div>
                                <p *ngIf="bookingForGuest.value == 'yes' && guestCount > 1 " class="choose_airpot_tag col-sm-12">
                                    <strong>*</strong> <span>Each guest visit will be counted as one visit and will be
                                        deducted from your
                                        remaining complimentary visits.</span>
                                </p>
                            </div>
                            <div class="row">
                                <div class="cols col-sm-12 form-single-container optional_document_upload">
                                    <div class="enq-num inp-label">Documents upload:</div>
                                    <p>Tticket copy</p>
                                    <div *ngIf="guest_document_error" class="alert alert-danger">
                                        <div *ngIf="guest_document_error">{{ guest_document_error_message }}</div>
                                    </div>
                                    <input id="guest_document" class="form-control guest_document_upload" type="file" accept="image/png, image/jpeg, image/pdf" formControlName="guest_document" (change)="upload($event)" multiple>
                                    <div *ngIf="guest_document && guest_document.invalid && (guest_document.touched || submitButtonSubmitted)" class="alert alert-danger">
                                        <div *ngIf="guest_document?.errors.required">Please upload all tickets for booking.
                                        </div>
                                    </div>
                                    <p>( Press CTRL and upload, if you want to upload multiple files)</p>
                                    <p>(PNG, JPEG, PDF)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showAirportVipPrivilegeFields" class="new_terms_content mt-5">
                <div class="pt-5">
                    <p> <input type="checkbox" formControlName="contact_info" required /> I hereby agree that my personal contact information (such as name, home address, home telephone or mobile number, fax number, email address) can be collected and stored
                        with Bank's service provider namely Poshvine with the objective of sharing such information with third parties (such as NatGeo, Mr and Mrs Smith, Headout etc.) for the purposes of booking.
                    </p>
                    <div *ngIf="contact_info.invalid && (contact_info.touched || submitButtonSubmitted)" class="alert alert-danger error">
                        <div *ngIf="contact_info.errors.required">Accept our conditions.</div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="showAirportVipPrivilegeFields" class="new_terms_content_class">
            <div>
                <input type="checkbox" formControlName="agree_terms" required /> I agree to the <a href="./assets/Airport_concierge_services_TnC.pdf" target="_blank">terms and conditions.</a>
                <div *ngIf="agree_terms.invalid && (agree_terms.touched || submitButtonSubmitted)" class="alert alert-danger error">
                    <div *ngIf="agree_terms.errors.required">Accept our conditions.</div>
                </div>
            </div>
            <button type="submit" mat-button class="btn btn-theme air-port-vip-privilages-submit" [disabled]="makingEnquiry">
                Pay Rs.1 authentication fee
                <i [class]="makingEnquiry ? 'fa fa-spinner fa-spin bin-loader' : ''"></i>
            </button>
        </div>

    </mat-dialog-content>



</form>

<mat-dialog-content class="mat-typography modal-bg" *ngIf="!showGuestCountvalidation">
    <h2 mat-dialog-title class="block">
        <span class="dialog-modal-title left">You are eligibility booking limit is over</span>
        <img src="../../assets/axis-a-logo-white.png" alt="logo" class="dialog-modal-logo img-fluid left">
    </h2>
    <div class="sub-title-grey">Click on below link to Go to list of bookings.</div>
    <a class="dropdown-item sub-lbl flight-booking-link" routerLink="/flight-bookings" (click)="close();"><i
            class="fa fa-angle-right select-down"></i> <span>Airport Concierge Service</span></a>